import React from "react"
import Layout from "@/components/layout"
import HeaderOne from "@/components/header-one"
import PageBanner from "@/components/page-banner"
import Footer from "@/components/footer-one"
import PrivacyPolicy from "@/components/privacy"
import SearchContextProvider from "@/context/search-context"
import MenuContextProvider from "@/context/menu-context"
import { Container } from "react-bootstrap"

const PrivacyPage = () => {
  return (
    <MenuContextProvider>
      <SearchContextProvider>
        <Layout PageTitle="Privacy">
          <HeaderOne />
          <PageBanner title="Privacy Policy" name="Privacy" />
          <section className="commonSection blogDetails">
            <Container>
              <div className="privacy">
                <PrivacyPolicy />
              </div>
            </Container>
          </section>
          <Footer />
        </Layout>
      </SearchContextProvider>
    </MenuContextProvider>
  )
}

export default PrivacyPage
